import styles from "./LoginForm.module.css";
import ButtonSubmit from "../styles/ButtonSubmit";
import SizedBox from "../utils/SizedBox";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useState } from 'react';

function LoginForm({ setIsLogged}) {

    const [schema, setSchema] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    let navigate = useNavigate();
    const handleLogin = (e) =>{

        e.preventDefault()

        const path = "home";
        const loginUrl = `http://177.70.26.96:17001/api/v1/app/login`;
        let errLogin = window.document.getElementById("err-login");
        let errEmpresa = window.document.getElementById("err-empresa");
        let errUsuario = window.document.getElementById("err-usuario");
        let errPassword = window.document.getElementById("err-password");

        let data = {
            "schema" : schema,
            "username" : username,
            "password" : btoa(password)
        }

        if (schema === ""){
            errEmpresa.style.display = "block";
            errUsuario.style.display = "none";
            errPassword.style.display = "none";
            errLogin.style.display="none";
            return;
        }

        if (username === ""){
            errEmpresa.style.display = "none";
            errUsuario.style.display = "block";
            errPassword.style.display = "none";
            errLogin.style.display="none";
            return;
        }

        if (password === ""){
            errEmpresa.style.display = "none";
            errUsuario.style.display = "none";
            errPassword.style.display = "block";
            errLogin.style.display="none";
            return;
        }

        axios.post(loginUrl, data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (response.status === 200) {

                if(response.data['success']) {

                    const employeeData = {
                        schemaName: schema,
                        fkEmployee: username.split('.')[0]
                    }

                    setIsLogged(true);

                    navigate(path, {
                        state: {
                            responseLogin: response.data,
                            employeeData: employeeData
                        },
                        replace: true
                    });
                }


            } else {
                errEmpresa.style.display = "none";
                errUsuario.style.display = "none";
                errPassword.style.display = "none";
                errLogin.style.display="block";
            }
        })
        .catch(error => {
            errEmpresa.style.display = "none";
            errUsuario.style.display = "none";
            errPassword.style.display = "none";
            errLogin.style.display="block";
            console.error(error);
        });

    }

    return (
       <div className={styles.form}>
           <form>
                <div>
                   <label> Empresa </label>
                   <input
                       type={"text"}
                       placeholder={"Digite o código da empresa"}
                       value={schema}
                       onChange={e => setSchema(e.target.value)}
                   />
                   <span id={"err-empresa"} className={styles.errLabel} > Este campo não pode ser vazio </span>
               </div>
                <SizedBox height={"1em"}/>
                <div>
                    <label> Usuário </label>
                    <input type={"text"}
                           placeholder={"Digite o seu usuário"}
                           value={username}
                           onChange={e => {setUsername(e.target.value)}}
                    />
                    <span id={"err-usuario"} className={styles.errLabel} > Este campo não pode ser vazio </span>
                </div>
                <SizedBox height={"1em"}/>
                <div>
                    <label> Senha </label>
                    <input
                        type={"password"}
                        placeholder={"Digite sua senha"}
                        value={password}
                        onChange={e => {setPassword(e.target.value)}}
                    />
                    <span id={"err-password"} className={styles.errLabel} > Este campo não pode ser vazio </span>
                </div>
                <SizedBox height={"2em"}/>
                <ButtonSubmit text={"Entrar"} onClick={handleLogin} />
                <span id={"err-login"} className={styles.errLabel}>  Falha ao logar, usuário ou senha incorretos! </span>
           </form>
       </div>
    )
}

export default LoginForm