import logo from '../../assets/images/sgp.png';
import Image from "../styles/Image";
import styles from "./DefaultNotLogged.module.css";
function DefaultNotLogged (){
    return (
        <div className={styles.wrapper}>
            <Image height={"100px"} width={"100px"} src={logo} alt={"logo"} />
            <p> Hm.... parece que você não está logado, vá para a página de <a href={"/"}>login</a></p>
        </div>
    );
}

export default DefaultNotLogged