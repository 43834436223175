// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_form__ML35I {
    width: 75%;
}

.LoginForm_form__ML35I div {
    display: flex;
    flex-direction: column;
}

.LoginForm_form__ML35I input {
    margin-top: .5em;
    height: 2em;
}


.LoginForm_form__ML35I label {
    font-weight: bold;
}

.LoginForm_errLabel__YDxCR {
    color: red;
    font-weight: normal;
    margin-left: .5em;
    margin-top: .5em;
    font-size: 12px;
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/LoginForm.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;;AAGA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".form {\n    width: 75%;\n}\n\n.form div {\n    display: flex;\n    flex-direction: column;\n}\n\n.form input {\n    margin-top: .5em;\n    height: 2em;\n}\n\n\n.form label {\n    font-weight: bold;\n}\n\n.errLabel {\n    color: red;\n    font-weight: normal;\n    margin-left: .5em;\n    margin-top: .5em;\n    font-size: 12px;\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `LoginForm_form__ML35I`,
	"errLabel": `LoginForm_errLabel__YDxCR`
};
export default ___CSS_LOADER_EXPORT___;
