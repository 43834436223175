import logo from "../../assets/images/sgp.png"
import Image from "../styles/Image";
import styles from "./Login.module.css";
import LoginForm from "../forms/LoginForm";
import SizedBox from "../utils/SizedBox";

function Login ( { setIsLogged }) {

    return(
        <div className={styles.login}>
            <Image alt={"logo"}  src={logo} />
            <SizedBox height={"1em"} />
            <LoginForm setIsLogged={setIsLogged} />
        </div>
    )
}

export default Login