import styles from "./GeralContent.module.css";
import Card from "../styles/Card";
import InfoTable from "../styles/InfoTable";
import SizedBox from "../utils/SizedBox";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function GeralContent () {

    //screen size utils
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function updateDimensions() {
            setWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
        }

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
    }, []);

    //fetch utils
    const location = useLocation();
    const employeeData = location.state?.employeeData;
    const url = `http://177.70.26.96:17001/api/v1/app/home-content/${employeeData['schemaName']}?id=${employeeData['fkEmployee']}`;

    //fetch data
    const [associations, setAssociations] = useState(0);
    const [clockIns, setClockIns] = useState(0);
    const [lastClockIn, setLastClockIn] = useState("N/A");
    const [lastFive, setLastFive] = useState([])


    useEffect(() => {

        axios.get(url)
            .then((response) => {
                console.log(response);
                setAssociations(response.data['association_no']);
                setClockIns(response.data['clock_ins_count']);
                setLastClockIn(response.data['last_clock_in']);
                setLastFive(response.data['last_five']);
            })
            .catch(err => {

                console.error(err);
            });
    }, [url]);


    //current month
    const currentDate = new Date();
    const options = { month: 'long', locale: 'pt-BR' };
    const formatter = new Intl.DateTimeFormat('pt-BR', options);
    const monthInPortuguese = formatter.format(currentDate);

    return (
       <>
           <div className={styles.wrapperCards}>
                <Card value={associations} bigText={"Associações"} smallText={"Considera o número de coletores associados a você."} />
                <SizedBox width={"1em"} />
                <Card value={clockIns} bigText={"Batidas"} smallText={"Considera o número de batidas por você neste mês."} />
                <SizedBox width={"1em"} />
               {
                   width > 400 ?
                    <Card bigText={lastClockIn.replace("T", " ás " )} smallText={"Última batida do mês."} /> : <> </>
               }

           </div>
           <SizedBox height={"0.5em"} />
           <div className={styles.wrapperInfo}>
                <InfoTable
                    tableDescription={`Últimas 5 batidas ( ${monthInPortuguese} )`}
                    tableData={lastFive}
                />
           </div>
       </>
    );
}

export default GeralContent