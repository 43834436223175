import './App.css';
import Login from "./components/pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import { useState } from "react";
import DefaultNotLogged from "./components/pages/DefaultNotLogged";

function App() {

    const [isLogged, setIsLogged] = useState(false);

    return (
      <Router>
        <div className="App">
            <Routes>
                { isLogged ?
                    <Route path={"/home"} element={<Home setIsLogged={setIsLogged} />}> </Route>
                    :
                    <>
                        <Route path={"/"} element={<Login setIsLogged={setIsLogged}  />}> </Route>
                        <Route path={"*"} element={<DefaultNotLogged />}> </Route>
                    </>
                }
            </Routes>
        </div>
      </Router>

    );
}

export default App;
