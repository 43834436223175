// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
Width value will vary depending on device, media query will resolve this problem
 */
.Home_wrapper__k869J {
    background: #0C2D32;
    border-radius: 2em;
}


.Home_navChild__yh20p {
    /* Wont able to see but important to constraint content child */
    height: 25%;
}

.Home_contentChild__fReDI {
    margin: 0 2em;
    border: white solid 1px;
    height: 70%;
}

/*Height and Width */
@media (min-height: 875px) and (min-width: 875px){
    .Home_wrapper__k869J {
        height: 60%;
    }
}

@media (min-height: 675px) and (max-height: 874px) and (min-width: 875px) {
    .Home_wrapper__k869J {
        height: 80%;
    }
}

/* Width */
@media (min-width: 1600px) {
    .Home_wrapper__k869J {
        width: 70%;
    }
}

@media (max-width: 600px) {
    .Home_wrapper__k869J {
        width: 100%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/pages/Home.module.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI,+DAA+D;IAC/D,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;AACf;;AAEA,oBAAoB;AACpB;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA,UAAU;AACV;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["/**\nWidth value will vary depending on device, media query will resolve this problem\n */\n.wrapper {\n    background: #0C2D32;\n    border-radius: 2em;\n}\n\n\n.navChild {\n    /* Wont able to see but important to constraint content child */\n    height: 25%;\n}\n\n.contentChild {\n    margin: 0 2em;\n    border: white solid 1px;\n    height: 70%;\n}\n\n/*Height and Width */\n@media (min-height: 875px) and (min-width: 875px){\n    .wrapper {\n        height: 60%;\n    }\n}\n\n@media (min-height: 675px) and (max-height: 874px) and (min-width: 875px) {\n    .wrapper {\n        height: 80%;\n    }\n}\n\n/* Width */\n@media (min-width: 1600px) {\n    .wrapper {\n        width: 70%;\n    }\n}\n\n@media (max-width: 600px) {\n    .wrapper {\n        width: 100%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Home_wrapper__k869J`,
	"navChild": `Home_navChild__yh20p`,
	"contentChild": `Home_contentChild__fReDI`
};
export default ___CSS_LOADER_EXPORT___;
