// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PeriodoContent_query__Ffmqt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2em;
}

.PeriodoContent_calendarSelect__RmuBk {
    display: flex;
    flex-direction: column;
}

.PeriodoContent_calendarSelect__RmuBk label {
    font-weight: bold;
}

.PeriodoContent_calendars__gcCvH {
    display: flex;
    flex-direction: row;
}

.PeriodoContent_cardResult__5lyuz {
    margin-left: 10px;
}

.PeriodoContent_errLabel__czfUM {
    margin-top: .5em;
    margin-left: .2em;
    color: red;
    display: none;
}

@media (min-width:  676px) and (max-height:  950px){
}

@media (min-width: 485px) and (max-width: 675px) {
    .PeriodoContent_cardResult__5lyuz {
        display: none;
    }
}

@media (max-width: 484px) {
    .PeriodoContent_cardResult__5lyuz {
        display: none;
    }
    .PeriodoContent_calendars__gcCvH {
        margin-top: 5px;
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/content/PeriodoContent.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,UAAU;IACV,aAAa;AACjB;;AAEA;AACA;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,eAAe;QACf,sBAAsB;IAC1B;AACJ","sourcesContent":[".query {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 2em;\n}\n\n.calendarSelect {\n    display: flex;\n    flex-direction: column;\n}\n\n.calendarSelect label {\n    font-weight: bold;\n}\n\n.calendars {\n    display: flex;\n    flex-direction: row;\n}\n\n.cardResult {\n    margin-left: 10px;\n}\n\n.errLabel {\n    margin-top: .5em;\n    margin-left: .2em;\n    color: red;\n    display: none;\n}\n\n@media (min-width:  676px) and (max-height:  950px){\n}\n\n@media (min-width: 485px) and (max-width: 675px) {\n    .cardResult {\n        display: none;\n    }\n}\n\n@media (max-width: 484px) {\n    .cardResult {\n        display: none;\n    }\n    .calendars {\n        margin-top: 5px;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"query": `PeriodoContent_query__Ffmqt`,
	"calendarSelect": `PeriodoContent_calendarSelect__RmuBk`,
	"calendars": `PeriodoContent_calendars__gcCvH`,
	"cardResult": `PeriodoContent_cardResult__5lyuz`,
	"errLabel": `PeriodoContent_errLabel__czfUM`
};
export default ___CSS_LOADER_EXPORT___;
