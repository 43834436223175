// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GeralContent_wrapperCards__Lk3qb {
    height: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
}

.GeralContent_wrapperInfo__fDT3W {
    height: 55%;

}

`, "",{"version":3,"sources":["webpack://./src/components/content/GeralContent.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,WAAW;;AAEf","sourcesContent":[".wrapperCards {\n    height: 25%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 1em;\n}\n\n.wrapperInfo {\n    height: 55%;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperCards": `GeralContent_wrapperCards__Lk3qb`,
	"wrapperInfo": `GeralContent_wrapperInfo__fDT3W`
};
export default ___CSS_LOADER_EXPORT___;
