// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__tJjgL {
    border: white solid 1px;
    display: flex;
    flex-direction: row;
}

.Card_bigText__05ixC {
    font-weight: bold;
}



@media (min-width: 675px) {
    .Card_card__tJjgL {

        padding: .5em;
    }
    .Card_smallText__06HbL {
        font-size: 12px;
        height: 100%;
    }
    .Card_value__\\+sg14 {
        font-size: 80px;
    }
}

@media (max-width: 675px){
    .Card_smallText__06HbL {
        font-size: 12px;
    }
    .Card_bigText__05ixC {
        font-size: 15px;
    }
    .Card_card__tJjgL {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 1em;
    }
    .Card_value__\\+sg14 {
        padding: 0;
        margin-top: 15px;
        font-size: 40px;
    }
    .Card_description__ou9wJ {
        margin-left: 10px;
    }

}
`, "",{"version":3,"sources":["webpack://./src/components/styles/Card.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;;;AAIA;IACI;;QAEI,aAAa;IACjB;IACA;QACI,eAAe;QACf,YAAY;IAChB;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,sBAAsB;QACtB,uBAAuB;QACvB,qBAAqB;QACrB,mBAAmB;QACnB,YAAY;IAChB;IACA;QACI,UAAU;QACV,gBAAgB;QAChB,eAAe;IACnB;IACA;QACI,iBAAiB;IACrB;;AAEJ","sourcesContent":[".card {\n    border: white solid 1px;\n    display: flex;\n    flex-direction: row;\n}\n\n.bigText {\n    font-weight: bold;\n}\n\n\n\n@media (min-width: 675px) {\n    .card {\n\n        padding: .5em;\n    }\n    .smallText {\n        font-size: 12px;\n        height: 100%;\n    }\n    .value {\n        font-size: 80px;\n    }\n}\n\n@media (max-width: 675px){\n    .smallText {\n        font-size: 12px;\n    }\n    .bigText {\n        font-size: 15px;\n    }\n    .card {\n        flex-direction: column;\n        justify-content: center;\n        align-content: center;\n        align-items: center;\n        padding: 1em;\n    }\n    .value {\n        padding: 0;\n        margin-top: 15px;\n        font-size: 40px;\n    }\n    .description {\n        margin-left: 10px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__tJjgL`,
	"bigText": `Card_bigText__05ixC`,
	"smallText": `Card_smallText__06HbL`,
	"value": `Card_value__+sg14`,
	"description": `Card_description__ou9wJ`
};
export default ___CSS_LOADER_EXPORT___;
