import styles from "./Home.module.css";
import Navbar from "../navbar/Navbar";
import GeralContent from "../content/GeralContent";
import PeriodoContent from "../content/PeriodoContent";
import { useState } from "react";
import { useLocation } from "react-router-dom";


function Home( { setIsLogged }) {

    /*
    const [height, setHeight]  = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function updateDimensions() {
            setWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
            setHeight(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
        }

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
    }, []); */

    const location = useLocation();
    const responseData = location.state?.responseLogin;

    const [isGeral, setIsGeral]  = useState(true)

    function goToGeral(){
        setIsGeral(true)
    }

    function goToPeriodos(){
        setIsGeral(false)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.navChild}>
                <Navbar
                    setIsLogged={setIsLogged}
                    geralClick={goToGeral}
                    periodoClick={goToPeriodos}
                    employee={responseData['employee_name']}
                    enterprise={responseData['enterprise_name']}
                    updateTime={responseData['update_time']}
                />
            </div>
            <div className={styles.contentChild}>
                {
                    isGeral ?
                        <GeralContent /> : <PeriodoContent />
                }
            </div>
        </div>
    );
}

export default Home