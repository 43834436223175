// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-height: 950px) {
    .Image_img__CYayO {
        height: 150px;
        width: 150px;
    }
}

@media (min-height: 750px) and (max-height: 948px) {
    .Image_img__CYayO {
        height: 140px;
        width: 140px;
    }
}


@media (min-height: 550px) and (max-height: 749px)  {
    .Image_img__CYayO {
        height: 130px;
        width: 130px;
    }
}

@media (min-height: 480px) and (max-height: 549px)  {
    .Image_img__CYayO {
        height: 95px;
        width: 95px;
    }
}

@media (max-height: 479px) {
    .Image_img__CYayO {
        height: 90px;
        width: 90px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Image.module.css"],"names":[],"mappings":";AACA;IACI;QACI,aAAa;QACb,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,YAAY;IAChB;AACJ;;;AAGA;IACI;QACI,aAAa;QACb,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":["\n@media (min-height: 950px) {\n    .img {\n        height: 150px;\n        width: 150px;\n    }\n}\n\n@media (min-height: 750px) and (max-height: 948px) {\n    .img {\n        height: 140px;\n        width: 140px;\n    }\n}\n\n\n@media (min-height: 550px) and (max-height: 749px)  {\n    .img {\n        height: 130px;\n        width: 130px;\n    }\n}\n\n@media (min-height: 480px) and (max-height: 549px)  {\n    .img {\n        height: 95px;\n        width: 95px;\n    }\n}\n\n@media (max-height: 479px) {\n    .img {\n        height: 90px;\n        width: 90px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `Image_img__CYayO`
};
export default ___CSS_LOADER_EXPORT___;
