import styles from "./Card.module.css";
import SizedBox from "../utils/SizedBox";
function Card({ value, bigText, smallText}) {
    return (
        <div className={styles.card}>
            <SizedBox width={"2em"} />
            <div className={styles.value}>
                <label> {value} </label>
            </div>
            <SizedBox width={"2em"} />
            <div className={styles.description}>
                <p className={styles.bigText}> {bigText} </p>
                <p className={styles.smallText}> {smallText} </p>
            </div>
        </div>
    );
}

export default Card