// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-height: 950px) {
    .Image2_img__DBVpe {
        height: 100px;
        width: 100px;
    }
}

@media (min-height: 750px) and (max-height: 948px) {
    .Image2_img__DBVpe {
        height: 95px;
        width: 95px;
    }
}


@media (min-height: 550px) and (max-height: 749px)  {
    .Image2_img__DBVpe {
        height: 90px;
        width: 90px;
    }
}

@media (min-height: 480px) and (max-height: 549px)  {
    .Image2_img__DBVpe {
        height: 85px;
        width: 85px;
    }
}

@media (max-height: 479px) {
    .Image2_img__DBVpe {
        height: 80px;
        width: 80px;
    }
}

@media (max-width: 674px)  {
    .Image2_img__DBVpe {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Image2.module.css"],"names":[],"mappings":";AACA;IACI;QACI,aAAa;QACb,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;;AAGA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n@media (min-height: 950px) {\n    .img {\n        height: 100px;\n        width: 100px;\n    }\n}\n\n@media (min-height: 750px) and (max-height: 948px) {\n    .img {\n        height: 95px;\n        width: 95px;\n    }\n}\n\n\n@media (min-height: 550px) and (max-height: 749px)  {\n    .img {\n        height: 90px;\n        width: 90px;\n    }\n}\n\n@media (min-height: 480px) and (max-height: 549px)  {\n    .img {\n        height: 85px;\n        width: 85px;\n    }\n}\n\n@media (max-height: 479px) {\n    .img {\n        height: 80px;\n        width: 80px;\n    }\n}\n\n@media (max-width: 674px)  {\n    .img {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `Image2_img__DBVpe`
};
export default ___CSS_LOADER_EXPORT___;
