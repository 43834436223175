// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_btn__D0ZOA {
    background-color: #03171A;
    color: white;
    font-weight: bold;
    padding: 0.7em 1.2em;
    transition: .5s;
    cursor: pointer;
    border: none;
    border-radius: 10px;
}

.Button_btn__D0ZOA:hover {
    color: #0C2D32;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Button.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,eAAe;IACf,eAAe;IACf,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".btn {\n    background-color: #03171A;\n    color: white;\n    font-weight: bold;\n    padding: 0.7em 1.2em;\n    transition: .5s;\n    cursor: pointer;\n    border: none;\n    border-radius: 10px;\n}\n\n.btn:hover {\n    color: #0C2D32;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Button_btn__D0ZOA`
};
export default ___CSS_LOADER_EXPORT___;
