import styles from "./PeriodoContent.module.css";
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SizedBox from "../utils/SizedBox";
import Card from "../styles/Card";
import ButtonSubmit from "../styles/ButtonSubmit";
import InfoTable from "../styles/InfoTable";
import axios from "axios";
import { useLocation } from "react-router-dom";

function PeriodoContent() {

    const location = useLocation();
    const employeeData = location.state?.employeeData;

    const [initDate, setInitDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [queryResponse, setQueryResponse] = useState([]);
    const [clockInNo, setClockInNo] = useState("0");


    function queryForMarks() {

        //validations
        let errLabel = document.getElementById('err-label');

        const isEndDateLater = endDate > initDate;

        if (!isEndDateLater || initDate == null || endDate == null) {
            errLabel.style.display = "block";
            return;
        }

        //prepare query
        const formattedInit = dateFormatter(initDate);
        const formattedEnd = dateFormatter(endDate)
        const url =  `http://177.70.26.96:17001/api/v1/app/query/${employeeData['schemaName']}?f=${employeeData['fkEmployee']}&i=${formattedInit}&e=${formattedEnd}`;
        

        axios.get(url)
            .then(response => {

                setClockInNo(response.data['clock_in_no']);
                setQueryResponse(response.data['markDTOs_in_period'])

            })
            .catch(err => {
                console.log(err);
            })

        errLabel.style.display = "none";
    }

    function handleInitDateChange(date) {
        if (date != null){
            setInitDate(date);
        } else {
            setInitDate(null);
        }
    }

    function handleEndDateChange(date) {
        if (date != null){
            setEndDate(date);
        } else {
            setEndDate(null);
        }
    }

    function dateFormatter(dateToFormat) {
        const year = dateToFormat.getFullYear();
        const month = String(dateToFormat.getMonth() + 1).padStart(2, '0');
        const day = String(dateToFormat.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;

    }

    return (
        <>
            <div className={styles.query}>
                <div className={styles.calendarSelect}>
                    <label> Selecione o período desejado </label>
                    <SizedBox height={"1em"} />
                    <div className={styles.calendars}>
                        <DatePicker
                            selected={initDate}
                            onChange={handleInitDateChange}
                            dateFormat="yyyy-MM-dd"
                            isClearable
                            placeholderText="Início"
                        />
                        <SizedBox width={"1em"} />
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="yyyy-MM-dd"
                            isClearable
                            placeholderText="Fim"
                        />
                    </div>
                    <SizedBox height={"1em"} />
                    <div>
                        <ButtonSubmit text={"Gerar"} onClick={queryForMarks} />
                        <span id={"err-label"} className={styles.errLabel} > Dados inválidos! </span>
                    </div>
                </div>
                <div className={styles.cardResult}>
                    <Card value={clockInNo} bigText={"Batidas"} smallText={"Número de batidas no período selecionado."}  />
                </div>
            </div>
            <div className={styles.results}>
                <InfoTable tableData={queryResponse} />
            </div>
        </>
    );
}

export default PeriodoContent