import styles from "./Navbar.module.css";
import logo from "../../assets/images/sgp.png";
import icEmployee from "../../assets/images/ic_username.png";
import icEnterprise from "../../assets/images/ic_enterprise.png";
import icUpdate from "../../assets/images/ic_network.png";
import Image2 from "../styles/Image2";
import SizedBox from "../utils/SizedBox";
import { Link } from "react-router-dom";

function Navbar({ geralClick, periodoClick, employee, enterprise, updateTime, setIsLogged }) {

    function logout() {
        setIsLogged(false);
    }

    return (
      <nav className={styles.navbar}>
          <div className={styles.menu}>
              <Image2 src={logo} alt={"Logo"} />
              <ul>
                  <li onClick={geralClick}> Geral </li>
                  <li onClick={periodoClick}> Período </li>
                  <li> <Link onClick={logout} className={styles.link} to={"/"}> Sair </Link> </li>
              </ul>
          </div>
          <div className={styles.information} >
              <div className={styles.infoChild}>
                  <img src={icEmployee} alt={"funcionario"} />
                  <SizedBox width={".5em"} />
                  <label>
                      Funcionário: <span> {employee} </span>
                  </label>
              </div>
              <SizedBox height={"1em"} />
              <div>
                  <div className={styles.infoChild}>
                      <img src={icEnterprise} alt={"funcionario"} />
                      <SizedBox width={".5em"} />
                      <label>
                          Empresa: <span> {enterprise.length <= 52
                          ? enterprise
                          : `${enterprise.slice(0, 50)}...`} </span>
                      </label>
                  </div>
              </div>
              <SizedBox height={"1em"} />
              <div>
                  <div className={styles.infoChild}>
                      <img src={icUpdate} alt={"funcionario"} />
                      <SizedBox width={".5em"} />
                      <label>
                          Atualização: <span> Todo dia às {updateTime} </span>
                      </label>
                  </div>
              </div>
          </div>
      </nav>
    );
}

export default Navbar