import SizedBox from "../utils/SizedBox";
import styles from "./InfoTable.module.css";
function InfoTable({ tableDescription, tableData }) {
    return(
       <div className={styles.wrapperTable}>
           <label> {tableDescription} </label>
           <SizedBox height={"1em"} />
           {
               (Array.isArray(tableData) && tableData.length > 0)  ?

               <table className={styles.table}>
                   <thead>
                   <tr>
                       <th> Dia </th>
                       <th> Horário </th>
                       <th> Coletor </th>
                       <th> E/S </th>
                   </tr>
                   </thead>
                   <tbody>
                   {
                       tableData.map ((rowData, index) => (
                           <tr key={ index }>
                               <td> { rowData.date } </td>
                               <td> { rowData.time } </td>
                               <td> {rowData.collector_name.length <= 14
                                   ? rowData.collector_name
                                   : `${rowData.collector_name.slice(0, 12)}...`} </td>
                               <td> { rowData.es_type } </td>
                           </tr>
                       ))
                   }
                   </tbody>
               </table>

                   :

               <div>
                   <center>
                       <h2> Não há dados para exibir </h2>
                   </center>
               </div>
           }

       </div>
    );
}

export default InfoTable

