import styles from "./Button.module.css";
function ButtonSubmit ({ text, onClick }) {

    return (
        <button className={styles.btn} onClick={onClick}>
            {text}
        </button>
    )

}
export default ButtonSubmit
