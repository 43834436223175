// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoTable_wrapperTable__yinGG {
    padding: 1em 1em;
    overflow-y: auto;
    max-height: 185px;
}

.InfoTable_wrapperTable__yinGG label{
    font-size: 20px;
    font-weight: bold;
}

.InfoTable_table__\\+VR31 {
    width: 100%;
    border: white 1px solid;
    padding: 0.5em;
}

.InfoTable_table__\\+VR31 tbody {
}

.InfoTable_table__\\+VR31 th {
    font-weight: bold;
    font-size: 16px;
}

.InfoTable_table__\\+VR31 th, td {
    text-align: left;
}

tr:nth-child(even) {
    background-color: #03171A;
}

`, "",{"version":3,"sources":["webpack://./src/components/styles/InfoTable.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,cAAc;AAClB;;AAEA;AACA;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".wrapperTable {\n    padding: 1em 1em;\n    overflow-y: auto;\n    max-height: 185px;\n}\n\n.wrapperTable label{\n    font-size: 20px;\n    font-weight: bold;\n}\n\n.table {\n    width: 100%;\n    border: white 1px solid;\n    padding: 0.5em;\n}\n\n.table tbody {\n}\n\n.table th {\n    font-weight: bold;\n    font-size: 16px;\n}\n\n.table th, td {\n    text-align: left;\n}\n\ntr:nth-child(even) {\n    background-color: #03171A;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperTable": `InfoTable_wrapperTable__yinGG`,
	"table": `InfoTable_table__+VR31`
};
export default ___CSS_LOADER_EXPORT___;
