// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login__44ysL {
    background: #0C2D32;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .5em;
}

/* Height */
@media (min-height: 950px) {
    .Login_login__44ysL {
        height: 50%;
    }
}

@media (min-height: 750px) and (max-height: 949px)  {
    .Login_login__44ysL {
        height: 60%;
    }
}

@media (min-height: 550px) and (max-height: 749px)  {
    .Login_login__44ysL {
        height: 80%;
    }
}

@media (min-height: 480px) and (max-height: 549px)  {
    .Login_login__44ysL {
        height: 90%;
    }
}

@media (max-height: 479px) {
    .Login_login__44ysL {
        height: 100%;
    }
}

/* Width */
@media (min-width: 1500px) {
    .Login_login__44ysL {
        width: 25%;
    }
}

@media (max-width: 449px) {
    .Login_login__44ysL {
        width: 100%;
    }
}

@media (min-width: 450px) and (max-width: 769px){
    .Login_login__44ysL {
        width: 80%;
    }
}

@media (min-width: 770px) and (max-width: 1024px){
    .Login_login__44ysL {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1499px){
    .Login_login__44ysL {
        width: 40%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Login.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA,WAAW;AACX;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA,UAAU;AACV;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".login {\n    background: #0C2D32;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border-radius: .5em;\n}\n\n/* Height */\n@media (min-height: 950px) {\n    .login {\n        height: 50%;\n    }\n}\n\n@media (min-height: 750px) and (max-height: 949px)  {\n    .login {\n        height: 60%;\n    }\n}\n\n@media (min-height: 550px) and (max-height: 749px)  {\n    .login {\n        height: 80%;\n    }\n}\n\n@media (min-height: 480px) and (max-height: 549px)  {\n    .login {\n        height: 90%;\n    }\n}\n\n@media (max-height: 479px) {\n    .login {\n        height: 100%;\n    }\n}\n\n/* Width */\n@media (min-width: 1500px) {\n    .login {\n        width: 25%;\n    }\n}\n\n@media (max-width: 449px) {\n    .login {\n        width: 100%;\n    }\n}\n\n@media (min-width: 450px) and (max-width: 769px){\n    .login {\n        width: 80%;\n    }\n}\n\n@media (min-width: 770px) and (max-width: 1024px){\n    .login {\n        width: 60%;\n    }\n}\n\n@media (min-width: 1025px) and (max-width: 1499px){\n    .login {\n        width: 40%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `Login_login__44ysL`
};
export default ___CSS_LOADER_EXPORT___;
